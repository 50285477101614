import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PublicGetSubjectPageResponse } from '@les-sherpas/sherpas-toolbox';

import SeoHeader from '@/components/LandingMozza/common/SeoHeader/SeoHeader';
import SeoTable from '@/components/LandingMozza/common/SeoTable/SeoTable';

import messages from './messages';

type Props = {
  links: PublicGetSubjectPageResponse['links'];
};

const SeoLinksSubjectTemplate: FC<Props> = ({ links }) => {
  const { formatMessage } = useIntl();
  const { levels, cities } = links;
  return (
    <>
      <SeoHeader />
      <SeoTable
        links={levels}
        title={formatMessage(messages.titleLevel)}
        type="subject"
      />
      <SeoTable
        links={cities}
        title={formatMessage(messages.titleCities)}
        type="location"
      />
    </>
  );
};

export default SeoLinksSubjectTemplate;
