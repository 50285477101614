import { defineMessages } from 'react-intl';

export default defineMessages({
  titleLevel: {
    id: 'landingMozza.landingSubject.SeoLinksSubjectTemplate.title',
    defaultMessage: 'Niveaux scolaires assurés',
  },
  titleCities: {
    id: 'landingMozza.landingSubject.SeoLinksSubjectTemplate.titleCities',
    defaultMessage: 'Villes de nos profs particuliers',
  },
});
