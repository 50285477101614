import { FC } from 'react';
import {
  PublicGetSubjectPageResponse,
  SearchResult,
} from '@les-sherpas/sherpas-toolbox';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import server, { baseUrl } from 'config';
import { GetStaticPaths, GetStaticPathsResult } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';

import getStructuredData from '@/components/LandingMozza/LandingSeo/LandingSubject/getStructuredData';
import LandingSubject from '@/components/LandingMozza/LandingSeo/LandingSubject/LandingSubject';
import { apiGetSubjects } from '@/shared/API/apiGetSubjects';
import { getListTeachers } from '@/utils/Seo.utils';

type Props = PublicGetSubjectPageResponse & {
  announces: SearchResult[];
};
const LandingSeoSubjectPage: FC<Props> = ({
  template,
  announces,
  subject,
  links,
}) => {
  const router = useRouter();
  const canonicalUrl = `${baseUrl}${router.asPath.replace('/', '')}`.split(
    '?'
  )[0];

  return (
    <>
      <Head>
        <title>{template.meta.title}</title>
        <meta name="description" content={template.meta.description} />
        <link rel="canonical" href={canonicalUrl} />
        <script
          key="structured-data"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getStructuredData({
              canonicalUrl,
              subject: subject.name,
              meta: {
                title: template.meta.title,
                description: template.meta.description,
              },
            }),
          }}
        />
      </Head>
      <LandingSubject
        template={template}
        announces={announces}
        subject={subject}
        links={links}
      />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = (): GetStaticPathsResult => ({
  paths: [{ params: { subject: 'maths' } }],
  fallback: 'blocking',
});

export async function getStaticProps({ params }) {
  const { subject } = params;
  const queryClient = new QueryClient();

  try {
    const { data } = await axios.get<PublicGetSubjectPageResponse>(
      `${server}seo-student-pages/public/get-subject-page/${subject}`,
      {
        validateStatus: (status) => status === 200,
      }
    );
    const [announces] = await Promise.all([
      getListTeachers(data.subject.name, null, null, 8),
      queryClient.prefetchQuery(['subject'], apiGetSubjects),
    ]);

    return {
      props: {
        dehydratedState: dehydrate(queryClient),
        announces,
        ...data,
        isTvBannerDisplayed: false,
      } satisfies Props & {
        isTvBannerDisplayed: boolean;
        dehydratedState: DehydratedState;
      },
      revalidate: 60 * 60 * 24,
    };
  } catch (e) {
    return {
      notFound: true,
    };
  }
}

export default LandingSeoSubjectPage;
