
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/cours/[subject]",
      function () {
        return require("private-next-pages/cours/[subject]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/cours/[subject]"])
      });
    }
  