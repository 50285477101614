import { TitleContent, TitleSubtitle } from '@les-sherpas/sherpas-toolbox';

import Fight from '@/components/LandingMozza/common/Fight/Fight';
import SEOFormattedMessage from '@/generic/SEOFormattedMessage/SEOFormattedMessage';

export default function SeoFight({
  fight,
}: {
  fight: TitleSubtitle & { items: TitleContent[] };
}) {
  return (
    <Fight
      title={
        fight.title ? (
          <SEOFormattedMessage
            id="fight.overloadedTitle"
            defaultMessage={fight.title}
          />
        ) : undefined
      }
      subtitle={
        fight.subtitle ? (
          <SEOFormattedMessage
            id="fight.overloadedSubtitle"
            defaultMessage={fight.subtitle}
          />
        ) : undefined
      }
      cards={
        fight.items.length > 0
          ? fight.items.map(({ title, content }, index) => ({
              title: (
                <SEOFormattedMessage
                  id={`fight.cards.overloadedTitle${index}`}
                  defaultMessage={title}
                />
              ),
              content: (
                <SEOFormattedMessage
                  id={`fight.cards.overloadedContent${index}`}
                  defaultMessage={content}
                />
              ),
            }))
          : undefined
      }
    />
  );
}
